export const environment = {
  production: true,
  apiUrl: 'https://staging.smatpay.africa:8443',
  reinsuranceApiUrl: 'https://reinsurance-api.smathub.com:8445',
  tokenKey: '',
  recaptcha: {
    clientKey: '6Lf0zn8qAAAAABPVVUrw1YP932C2DtGJ34mSldYP',
    serverKey: '6Lf0zn8qAAAAAMe-ZJv0IXfsa7qrRtk35DiAxtuv',
  },
  CI: true,
};
